var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('b-modal',{attrs:{"title":"Изменение автора","hide-header-close":"","body-class":"position-static","id":_vm.modalId,"ok-disabled":_vm.isLoading,"cancel-disabled":_vm.isLoading},on:{"hide":_vm.handleHide,"ok":_vm.handleOk},scopedSlots:_vm._u([{key:"modal-footer",fn:function(ref){
var ok = ref.ok;
var cancel = ref.cancel;
return [_c('b-button',{staticClass:"mr-auto",attrs:{"variant":"outline-danger"},on:{"click":_vm.handleDelete}},[_vm._v(" Удалить ")]),_c('b-button',{attrs:{"disabled":_vm.isLoading},on:{"click":cancel}},[_vm._v(" Отменить ")]),_c('b-button',{attrs:{"variant":"primary","disabled":_vm.isLoading},on:{"click":ok}},[_vm._v(" Изменить ")])]}}])},[_c('b-overlay',{attrs:{"show":_vm.isLoading,"rounded":""}},[_c('b-alert',{attrs:{"show":Object.keys(_vm.serverError).length > 0,"variant":"danger"}},[_vm._v(" Ошибка "+_vm._s(_vm.serverError.description)+" "),(_vm.serverError.status !== 422)?[_vm._v(" "+_vm._s(_vm.serverError.status)+" ")]:_vm._e()],2),_c('b-form',{on:{"submit":function($event){$event.stopPropagation();$event.preventDefault();return _vm.onSubmit.apply(null, arguments)}}},[_c('b-form-group',{attrs:{"label":"Фотография"}},[_c('b-row',[_c('b-col',[_c('image-picker',{attrs:{"modal-id":"authorImage"},model:{value:(_vm.form.image),callback:function ($$v) {_vm.$set(_vm.form, "image", $$v)},expression:"form.image"}}),_c('div',{staticClass:"image",style:({
                backgroundImage: _vm.form.image
                  ? ("url(" + (_vm.form.image.preview) + ")")
                  : '',
              })}),_c('b-button',{directives:[{name:"b-modal",rawName:"v-b-modal.authorImage",modifiers:{"authorImage":true}}],staticClass:"mt-2"},[_vm._v(" Изменить ")])],1),_c('b-col',[_c('b-form-group',{attrs:{"label":"Alt"}},[_vm._v(" "+_vm._s(_vm.form.image.alt)+" ")]),_c('b-form-group',{attrs:{"label":"Description"}},[_vm._v(" "+_vm._s(_vm.form.image.title)+" ")])],1)],1),_c('b-form-invalid-feedback',{attrs:{"state":_vm.$v.form.image.$dirty ? !_vm.$v.form.image.$error : null}},[(!_vm.$v.form.image.required)?_c('div',[_vm._v("Поле обязательно для заполнения")]):_vm._e()])],1),_c('h5',[_vm._v("Имя")]),(_vm.languages.request.isSuccess)?_vm._l((_vm.languages.value),function(language){return _c('b-form-group',{key:language.slug,attrs:{"label":language.name,"label-for":("name[" + (language.slug) + "]")}},[_c('b-form-input',{attrs:{"id":("name[" + (language.slug) + "]"),"state":_vm.$v.form.name[language.slug].$dirty
              ? !_vm.$v.form.name[language.slug].$error
              : null,"aria-describedby":("name-" + (language.slug) + "-live-feedback")},model:{value:(_vm.form.name[language.slug]),callback:function ($$v) {_vm.$set(_vm.form.name, language.slug, $$v)},expression:"form.name[language.slug]"}}),_c('b-form-invalid-feedback',{attrs:{"id":("name-" + (language.slug) + "-live-feedback")}},[(!_vm.$v.form.name[language.slug].required)?_c('div',[_vm._v(" Поле обязательно для заполнения ")]):_vm._e(),(!_vm.$v.form.name[language.slug].serverValidationError)?_vm._l((_vm.serverValidationErrors.name[language.slug]),function(error,index){return _c('div',{key:("name-" + (language.slug) + "-serverValidationError-" + index)},[_vm._v(" "+_vm._s(error)+" ")])}):_vm._e()],2)],1)}):_vm._e()],2)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }