var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('b-modal',{attrs:{"title":"Выбор изображения","ok-title":"Выбрать","cancel-title":"Отменить","size":"xl","id":_vm.modalId,"ok-disabled":!_vm.selected.length},on:{"hide":_vm.handleHide,"ok":_vm.handleOk}},[(!_vm.images.request.isSuccess)?[_vm._v(" Загружаем картинки ")]:[_c('div',{staticClass:"d-flex flex-wrap m-n2"},_vm._l((_vm.images.value),function(image,i){return _c('div',{key:("image-" + i),class:[
            'image m-2',
            {
              image_selected: _vm.isImageSelected(image),
            }
          ],style:({
            backgroundImage: ("url(" + (image.preview) + ")")
          }),on:{"click":function($event){_vm.handleSelect()(image)}}})}),0)],(_vm.selected)?_vm._l((_vm.selected),function(image,i){return _c('b-row',{key:("selected-image-" + i),staticClass:"mt-5"},[_c('b-col',{attrs:{"cols":"12","md":"auto"}},[_c('div',{staticClass:"image image_preview",style:({
            backgroundImage: ("url(" + (image.preview) + ")")
          })})]),_c('b-col',[_c('b-form',[_c('b-form-group',{attrs:{"label":"alt"}},[_c('b-form-input',{attrs:{"type":"text"},model:{value:(image.alt),callback:function ($$v) {_vm.$set(image, "alt", $$v)},expression:"image.alt"}})],1),_c('b-form-group',{attrs:{"label":"title"}},[_c('b-form-input',{model:{value:(image.title),callback:function ($$v) {_vm.$set(image, "title", $$v)},expression:"image.title"}})],1)],1)],1)],1)}):_vm._e()],2)}
var staticRenderFns = []

export { render, staticRenderFns }