<template>
  <b-modal
    title="Выбор изображения"
    ok-title="Выбрать"
    cancel-title="Отменить"
    size="xl"
    :id="modalId"
    :ok-disabled="!selected.length"
    @hide="handleHide"
    @ok="handleOk"
  >
    <template v-if="!images.request.isSuccess">
      Загружаем картинки
    </template>
    <template v-else>
      <div class="d-flex flex-wrap m-n2">
        <div
            v-for="(image, i) in images.value"
            :key="`image-${i}`"
            :class="[
              'image m-2',
              {
                image_selected: isImageSelected(image),
              }
            ]"
            :style="{
              backgroundImage: `url(${image.preview})`
            }"
            @click="handleSelect()(image)"
          />
      </div>
    </template>
    <template v-if="selected">
      <b-row
        class="mt-5"
        v-for="(image, i) in selected"
        :key="`selected-image-${i}`"
      >
        <b-col cols="12" md="auto">
          <div
            class="image image_preview"
            :style="{
              backgroundImage: `url(${image.preview})`
            }"
          />
        </b-col>
        <b-col>
          <b-form>
            <b-form-group
              label="alt"
            >
              <b-form-input
                v-model="image.alt"
                type="text"
              />
            </b-form-group>
            <b-form-group
              label="title"
            >
              <b-form-input
                v-model="image.title"
              />
            </b-form-group>
          </b-form>
        </b-col>
      </b-row>
    </template>
  </b-modal>
</template>

<script>
import find from 'lodash/find';
import reject from 'lodash/reject';
import cloneDeep from 'lodash/cloneDeep';
import isEmpty from 'lodash/isEmpty';
import { mapState } from 'vuex';

import { FETCH_IMAGES } from '../store/actions.type';

export default {
  props: {
    value: {
      type: [Array, Object],
    },
    modalId: {
      required: true,
    },
    multiple: {
      type: Boolean,
      default: false,
    },
  },
  watch: {
    value: {
      immediate: true,
      handler(value) {
        this.setValue(value);
      },
    },
  },
  data() {
    return {
      selected: [],
    };
  },
  computed: {
    ...mapState({
      images: ({ image: { images: value, request } }) => ({
        request,
        value,
      }),
    }),
  },
  methods: {
    isImageSelected(image) {
      return !!find(this.selected, { id: image.id });
    },
    setValue(value) {
      if (this.value && this.multiple) {
        this.selected = cloneDeep(value);
      } else if (this.value && !isEmpty(this.value)) {
        this.selected = [{
          ...value,
        }];
      } else {
        this.selected = [];
      }
    },
    handleHide() {
      if (this.value) {
        this.setValue(this.value);
      } else {
        this.selected = [];
      }
    },
    handleOk() {
      this.$emit('input', this.multiple ? this.selected : this.selected[0]);
    },
    handleSelect() {
      return (image) => {
        const imageSelected = find(this.selected, { id: image.id });
        if (imageSelected) {
          this.selected = reject(this.selected, imageSelected);
        } else if (this.multiple) {
          this.selected.push({
            ...image,
            alt: undefined,
            title: undefined,
          });
        } else {
          this.selected = [{
            ...image,
            alt: undefined,
            title: undefined,
          }];
        }
      };
    },
  },
  beforeMount() {
    this.$store.dispatch(FETCH_IMAGES);
  },
};
</script>

<style scoped lang="stylus">
.image
  position relative
  cursor pointer
  width 75px
  height 75px
  background-size contain
  background-position center
  background-repeat no-repeat
  border 1px solid rgba(#000, 0.5)
  border-radius 4px
  &_preview
    margin 0
    cursor initial
  &_selected:after
    content ''
    display block
    position absolute
    width 100%
    height 100%
    background-color red
    opacity 0.25
</style>
