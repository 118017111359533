<template>
  <b-container>
    <blog-author-create
      modal-id="blogAuthorCreate"
      @created="handleCreated"
    />
    <blog-author-edit
      modal-id="blogAuthorEdit"
      :author="selectedAuthor"
      @updated="handleUpdated"
      @deleted="handleDeleted"
    />
    <h1 class="mt-2">
      Авторы
      <b-button v-b-modal.blogAuthorCreate>
        Добавить
      </b-button>
    </h1>
    <b-table
      stacked="lg"
      responsive small striped borderless
      :items="items"
      :fields="fields"
      :busy="!authors.request.isSuccess"
    >
      <template #table-busy>
        <div class="text-center text-danger my-2">
          <template v-if="authors.request.isLoading">
            <b-spinner class="align-middle"></b-spinner>
          </template>
          <strong class="ml-2">
            <template v-if="authors.request.isLoading">
              Загрузка авторов...
            </template>
            <template v-else-if="authors.request.isError">
              Ошибка загрузки авторов. Повтор через {{ authorRequestDelay }} сек.
            </template>
            <template v-else-if="authors.request.isFatalError">
              Ошибка. Попробуйте позже.
            </template>
          </strong>
        </div>
      </template>
      <template #cell(image)="row">
        <div
          class="image"
          :style="{
            backgroundImage: row.item.data.image
              ? `url(${row.item.data.image.preview})`
              : '',
          }"
        />
      </template>
      <template v-slot:cell(show_details)="row">
        <b-button
          size="sm"
          v-b-modal.blogAuthorEdit
          @click="() => {
            selectedAuthor = row.item;
          }"
        >
          Изменить
        </b-button>
      </template>
    </b-table>
  </b-container>
</template>

<script>
import map from 'lodash/map';
import findIndex from 'lodash/findIndex';
import reject from 'lodash/reject';
import { mapState } from 'vuex';
import BlogAuthorCreate from './components/BlogAuthorCreate.vue';
import BlogAuthorEdit from './components/BlogAuthorEdit.vue';

export default {
  components: {
    BlogAuthorCreate,
    BlogAuthorEdit,
  },
  data() {
    return {
      selectedAuthor: undefined,
      authors: {
        request: {},
        value: undefined,
      },
      fields: [
        {
          key: 'image',
          label: 'Фотография',
        },
        {
          key: 'name',
          label: 'Имя',
        },
        {
          key: 'show_details',
          label: 'Действие',
        },
      ],
    };
  },
  computed: {
    ...mapState({
      timestamp: ({ timestamp }) => timestamp,
      languages: ({ language: { languages: value, current, request } }) => ({
        current,
        value,
        request,
      }),
    }),
    authorRequestDelay() {
      if (!this.authors.request.isError) {
        return 0;
      }
      const ms = this.authors.request.delay - (
        this.timestamp - this.authors.request.lastRequestTime
      );
      return Math.floor(ms / 1000) + 1;
    },
    items() {
      if (!this.authors.request.isSuccess) {
        return [];
      }
      if (!this.languages.current) {
        return map(this.authors.value, (author) => ({
          ...author,
          name: JSON.stringify(author.data.name),
        }));
      }
      return map(this.authors.value, (author) => ({
        ...author,
        name: author.data.name[this.languages.current],
      }));
    },
  },
  methods: {
    handleCreated(author) {
      this.authors.value.push(author);
    },
    handleUpdated(author) {
      this.authors.value.splice(
        findIndex(this.authors.value, { id: author.id }),
        1,
        author,
      );
    },
    handleDeleted(author) {
      this.authors.value = reject(this.authors.value, {
        id: author.id,
      });
    },
  },
  async beforeMount() {
    try {
      const { data: { authors } } = await this.axios.get('blog/authors', {
        'axios-retry': {
          retries: 5,
          beforeTry: () => {
            this.authors.request = {
              isLoading: true,
              isError: false,
            };
          },
          afterTry: ({ delay, lastRequestTime }) => {
            this.authors.request = {
              isLoading: false,
              isError: true,
              delay,
              lastRequestTime,
            };
          },
        },
      });
      this.authors.request = {
        isSuccess: true,
      };
      this.authors.value = authors;
    } catch (e) {
      this.authors.request = {
        isFatalError: true,
      };
    }
  },
};
</script>

<style scoped lang="stylus">
.image
  width 100px
  height 100px
  border-radius 50px
  background-size cover
  background-position center
  background-color #fff
  border 1px solid rgba(#000, 0.5)
</style>
