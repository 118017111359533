<template>
  <b-modal
    title="Изменение автора"
    hide-header-close
    body-class="position-static"
    :id="modalId"
    :ok-disabled="isLoading"
    :cancel-disabled="isLoading"
    @hide="handleHide"
    @ok="handleOk"
  >
    <template v-slot:modal-footer="{ ok, cancel }">
      <b-button
        class="mr-auto"
        variant="outline-danger"
        @click="handleDelete"
      >
        Удалить
      </b-button>
      <b-button
        :disabled="isLoading"
        @click="cancel"
      >
        Отменить
      </b-button>
      <b-button
        variant="primary"
        :disabled="isLoading"
        @click="ok"
      >
        Изменить
      </b-button>
    </template>
    <b-overlay :show="isLoading" rounded>
      <b-alert
        :show="Object.keys(serverError).length > 0"
        variant="danger"
      >
        Ошибка {{ serverError.description }}
        <template
          v-if="serverError.status !== 422"
        >
          {{ serverError.status }}
        </template>
      </b-alert>
      <b-form @submit.stop.prevent="onSubmit">
        <b-form-group
          label="Фотография"
        >
        <b-row>
          <b-col>
              <image-picker
                modal-id="authorImage"
                v-model="form.image"
              />
              <div
                class="image"
                :style="{
                  backgroundImage: form.image
                    ? `url(${form.image.preview})`
                    : '',
                }"
              />
              <b-button class="mt-2" v-b-modal.authorImage>
                Изменить
              </b-button>
            </b-col>
            <b-col>
              <b-form-group label="Alt">
                {{ form.image.alt }}
              </b-form-group>
              <b-form-group label="Description">
                {{ form.image.title }}
              </b-form-group>
            </b-col>
          </b-row>
          <b-form-invalid-feedback
            :state="$v.form.image.$dirty ? !$v.form.image.$error : null"
          >
            <div v-if="!$v.form.image.required">Поле обязательно для заполнения</div>
          </b-form-invalid-feedback>
        </b-form-group>
        <h5>Имя</h5>
        <template v-if="languages.request.isSuccess">
          <b-form-group
            v-for="language in languages.value"
            :key="language.slug"
            :label="language.name"
            :label-for="`name[${language.slug}]`"
          >
            <b-form-input
              :id="`name[${language.slug}]`"
              v-model="form.name[language.slug]"
              :state="$v.form.name[language.slug].$dirty
                ? !$v.form.name[language.slug].$error
                : null"
              :aria-describedby="`name-${language.slug}-live-feedback`"
            ></b-form-input>
            <b-form-invalid-feedback
              :id="`name-${language.slug}-live-feedback`"
            >
              <div v-if="!$v.form.name[language.slug].required">
                Поле обязательно для заполнения
              </div>
              <template v-if="!$v.form.name[language.slug].serverValidationError">
                <div
                  v-for="(error, index) in serverValidationErrors.name[language.slug]"
                  :key="`name-${language.slug}-serverValidationError-${index}`"
                >
                  {{ error }}
                </div>
            </template>
            </b-form-invalid-feedback>
          </b-form-group>
        </template>
      </b-form>
    </b-overlay>
  </b-modal>
</template>

<script>
import flow from 'lodash/flow';
import keyBy from 'lodash/keyBy';
import mapValues from 'lodash/mapValues';
import cloneDeep from 'lodash/cloneDeep';
import { validationMixin } from 'vuelidate';
import {
  required,
} from 'vuelidate/lib/validators';
import { mapState } from 'vuex';

import ImagePicker from '@/components/ImagePicker.vue';

export default {
  mixins: [
    validationMixin,
  ],
  components: {
    ImagePicker,
  },
  data() {
    return {
      isLoading: false,
      form: {
        image: {},
        name: {},
      },
      serverValidationErrors: {},
      serverError: {},
    };
  },
  props: {
    modalId: {
      required: true,
    },
    author: {
      required: true,
    },
  },
  computed: {
    ...mapState({
      timestamp: ({ timestamp }) => timestamp,
      languages: ({ language: { languages: value, request } }) => ({
        value,
        request,
      }),
    }),
  },
  watch: {
    author(value) {
      this.form = cloneDeep(value.data);
    },
    form: {
      deep: true,
      handler() {
        this.serverValidationErrors = {};
        this.serverError = {};
      },
    },
  },
  validations() {
    return {
      form: {
        image: {
          required,
          serverValidationError: () => !this.serverValidationErrors.image,
        },
        name: {
          ...flow(
            (languages) => keyBy(languages, 'slug'),
            (languages) => mapValues(languages, (language) => ({
              required,
              serverValidationError: () => !(this.serverValidationErrors.name
                && this.serverValidationErrors.name[language.slug]),
            })),
          )(this.languages.value),
        },
      },
    };
  },
  methods: {
    handleOk(bvModalEvt) {
      bvModalEvt.preventDefault();
      this.handleSubmit();
    },
    async handleDelete() {
      try {
        this.isLoading = true;

        const {
          data: { author },
        } = await this.axios.delete(`/blog/authors/${this.author.id}`);

        if (!author) {
          throw new Error('Ошибка сервера');
        }

        this.form = {
          image: {},
          name: {},
        };

        this.$emit('deleted', author);
        // this.$v.form.$reset();
        this.$nextTick(() => {
          this.$bvModal.hide(this.modalId);
        });
      } catch (e) {
        this.serverError = {
          status: e.response ? e.response.status : undefined,
          description: 'сервера',
        };
      }
      this.isLoading = false;
    },
    handleHide(bvModalEvt) {
      if (this.isLoading) {
        bvModalEvt.preventDefault();
      }
    },
    async handleSubmit() {
      this.$v.form.$touch();

      if (this.$v.form.$anyError) {
        return;
      }

      try {
        this.isLoading = true;

        const { data: { author } } = await this.axios.put(`/blog/authors/${this.author.id}`, {
          data: this.form,
        });

        if (!author) {
          throw new Error('Ошибка сервера');
        }

        this.$emit('updated', author);

        this.$v.form.$reset();

        this.$nextTick(() => {
          this.$bvModal.hide(this.modalId);
        });
      } catch (e) {
        this.serverError = {
          status: e.response ? e.response.status : undefined,
          description: e.response && e.response.status === 422 ? 'валидации' : 'сервера',
        };
        this.serverValidationErrors = e.response && e.response.data && e.response.data.errors
          ? e.response.data.errors
          : {};
      }
      this.isLoading = false;
    },
  },
};
</script>

<style scoped lang="stylus">
.image
  width 100px
  height 100px
  border-radius 50px
  background-size cover
  background-position center
  border 1px solid rgba(#000, 0.5)
</style>
